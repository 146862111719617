
import { Component, Prop, Vue } from "vue-property-decorator";
import { ClaimCategory, Factcheck } from "@/types";

import * as ClaimCategoryService from "@/services/ClaimCategoryService";
import * as FactcheckServices from "@/services/FactCheckService";
import * as StorageServices from "@/services/StorageServices";

import { ROOT_ACTIONS } from "@/store/actions";

@Component({
  components: {
    DeleteFactcheckDialog: () =>
      import("@/components/dialogs/DeleteFactcheckDialog.vue"),
    EditFactcheckSeoDialog: () => import("@/components/dialogs/EditFactcheckSeoDialog.vue"),
    ClaimForm: () => import("@/components/forms/ClaimForm.vue"),
    ClaimReviewForm: () => import("@/components/forms/ClaimReviewForm.vue"),
    FactcheckForm: () => import("@/components/forms/FactcheckForm.vue"),
  },
})
export default class EditSingleArticleSection extends Vue {
  @Prop({ required: true }) factcheck!: Factcheck;
  tab = 0;

  search = "";

  claimReviewPreviewDialog = false;

  claimCategoriesCopy = this.factcheck.Claim?.ClaimCategories

  factcheckCopy = { ...this.factcheck };
  claimCopy = { ...this.factcheck.Claim, date: (this.factcheck.Claim!.date as string).substring(0, 10) }
  claimReviewsCopy = this.factcheck.Claim?.ClaimReviews.map(e => {
    return {
      ...e,
      date: (e.date as string).substring(0, 10)
    }
  });

  claimCategories = [] as ClaimCategory[];

  dialogs = {
    datePicker: false,
    newCategory: false,
    deleteFactcheck: false,
    editSeo: false
  };

  loading = {
    save: false,
    publishClaimReview: false,
    saveClaimReview: false,
    swapPrimary: false,
  };

  async mounted() {
    this.claimCategories = await ClaimCategoryService.GetClaimCategories();
  }

  async publishClaimReview() {
    try {
      this.loading.publishClaimReview = true;
      await FactcheckServices.PublishClaimReview(this.factcheckCopy.id as string)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Claimreview gepubliceerd",
      });
      this.$emit('refresh')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.publishClaimReview = false;
    }
  }

  get claimReviewPreview() {
    const factcheck = this.factcheckCopy;
    if (!factcheck) return {};
    const claim = factcheck.Claim;
    if (!claim) return {};
    const partner = factcheck.Claim!.ClaimReviews[0].Partner;
    if (!partner) return {};
    const claimreview = factcheck.Claim!.ClaimReviews[0];
    if (!claimreview) return {};
    return {
      "name": factcheck.title,
      "pageUrl": factcheck.Claim!.ClaimReviews[0].url,
      "publishDate": new Date().toISOString(),
      "claimReviewAuthor": {
        "name": partner.name,
        "imageUrl": partner.imageUrl,
      },
      "claimReviewMarkups": [
        {
          "url": factcheck.Claim!.ClaimReviews[0].url,
          "claimReviewed": claim.text,
          "claimDate": claim.date,
          "claimLocation": "",
          "claimFirstAppearance": claim.source,
          "claimAppearances": [
            claim.source,
          ],
          "claimAuthor": {
            "name": claim.claimant
          },
          "rating": {
            "textualRating": claimreview.textualRating,
            "ratingValue": claimreview.numericRating,
            "worstRating": 1,
            "bestRating": 5
          }
        }
      ],
      "versionId": new Date().toISOString()
    }
  }

  setClaimcategories(claimCategories: ClaimCategory[]) {
    console.log('setClaimcategories', claimCategories)
    this.claimCategoriesCopy = claimCategories;
  }

  async saveFactcheck() {
    try {
      this.loading.save = true;
      await FactcheckServices.UpdateFactcheck({
        id: this.factcheckCopy.id,
        description: this.factcheckCopy.description,
        title: this.factcheckCopy.title,
        type: this.factcheckCopy.type,
        childFriendly: this.factcheckCopy.childFriendly,
      });

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Opgeslagen",
      });
      this.$emit('refresh')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.save = false;
    }
  }

  async setPrimary(claimReviewId: string | undefined) {
    if (!claimReviewId) return;
    try {
      this.loading.swapPrimary = true;
      const claimreviews = this.claimReviewsCopy
      if (!claimreviews) return;
      for (let cr of claimreviews) {
        if (cr.id === claimReviewId) {
          cr.isPriority = true;
        } else {
          cr.isPriority = false;
        }
        await FactcheckServices.UpdateClaimReview({
          id: cr.id,
          isPriority: cr.isPriority,
        })
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.swapPrimary = false;
    }
  }

  async saveClaimReview(id: string | undefined) {
    if (id) {
      this.loading.saveClaimReview = true;
      const crc = this.claimReviewsCopy?.find(e => e.id === id);
      if (crc) {
        try {
          this.loading.save = true;
          await FactcheckServices.UpdateClaimReview({
            id,
            conclusion: crc.conclusion,
            date: crc.date,
            textualRating: crc.textualRating,
            numericRating: crc.numericRating,
            url: crc.url,
            isPriority: crc.isPriority,
            countryOfOrigin: crc.countryOfOrigin,
            retractImage: crc.retractImage,
          })

          let htmlElement = document.getElementById(`photo-${id}`) as HTMLInputElement;

          if (htmlElement) {
            if (htmlElement.files) {
              if (htmlElement.files[0]) {
                const file = htmlElement.files[0];
                await StorageServices.UploadPhoto(
                  `images/${id}`,
                  file
                );
              }
            }
          }

          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: "success",
            text: "Opgeslagen",
          });
        } catch (error) {
          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: "error",
            text: "Error",
          });
        } finally {
          this.loading.save = false;
          this.loading.saveClaimReview = false;
        }

      }
    }
  }

  async saveClaim() {
    if (!this.claimCategoriesCopy) return;
    for (let i = 0; i < this.claimCategoriesCopy.length; i++) {
      const cc = this.claimCategoriesCopy[i];
      if (typeof cc === 'string') {
        const category = await ClaimCategoryService.CreateClaimCategory(cc);
        this.claimCategoriesCopy[this.claimCategoriesCopy.indexOf(cc)] = category;
      }
    }

    try {
      this.loading.save = true;
      await FactcheckServices.UpdateClaim({
        ...this.claimCopy,
      });
      await FactcheckServices.SetClaimCategories(this.claimCopy.id!, this.claimCategoriesCopy.map(e => e.id))


      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Opgeslagen",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.save = false;
    }
  }
}
