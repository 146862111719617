import { storage } from "@/firebase"


export const UploadPhoto = async (remotePath: string, file: File | Blob): Promise<void> => {
  await storage().ref(remotePath).put(file, {
    contentType: file.type
  });
}

export const GetDownloadUrl = async (remotePath: string): Promise<string> => {
  const url = await storage().ref(remotePath).getDownloadURL();
  return url;
}