import { firestore } from "@/firebase";
import { ClaimCategory } from "@/types";

import api from '@/plugins/axios';

export const GetClaimCategories = async (): Promise<ClaimCategory[]> => {
  const { data } = await api.get<ClaimCategory[]>('/claim-categories')

  return data;
}

export const CreateClaimCategory = async (name: string): Promise<ClaimCategory> => {
  const { data } = await api.post<ClaimCategory>('/claim-categories', {
    nl: name
  })

  return data;
}

export const DeleteClaimCategory = async (id: string): Promise<void> => {
  await api.delete(`/claim-categories/${id}`);

  return;
}

export const UpdateClaimCategory = async (claimCategory: Partial<ClaimCategory>): Promise<ClaimCategory> => {
  const { data } = await api.patch<ClaimCategory>(`/claim-categories/${claimCategory.id}`, {
    name: claimCategory.nl
  });

  return data;
}